<template>
  <div class="transacrion">
    <el-table :data="transactions.data" :row-class-name="tableRowClassName">
      <el-table-column prop="id" label="#" width="80" />
      <el-table-column prop="value" label="Сумма" />
      <el-table-column prop="type" label="Тип" />
      <el-table-column prop="order.date" label="Дата заказа" :formatter="formatterText" />
      <el-table-column prop="date" label="Дата и время" />
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FETCH_TRANSACTIONS } from '@/app/clients/shared/state/types/action_types';
import AppTable from '@/app/shared/components/app-table/app-table.vue';

export default {
  name: 'Transactions',
  components: { AppTable },
  data() {
    return {
      fields: [
        { label: '#', value: 'id' },
        { label: 'Сумма', value: 'value' },
        { label: 'Тип', value: 'type' },
        { label: 'Дата заказа', value: 'order.date' },
        { label: 'Дата и время', value: 'date' },
      ],
    };
  },
  computed: {
    ...mapState({
      transactions: (state) => state.clientsState.clientsData.transactions,
    }),
  },
  async mounted() {
    await this.$store.dispatch(FETCH_TRANSACTIONS, { id: this.$route.params.id });
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.type === 'Приход') {
        return 'success-row';
      }
      return '';
    },
    formatterText(row, column, value) {
      return value || '-';
    },
    async changePage(page) {
      await this.$store.dispatch(FETCH_TRANSACTIONS, {
        id: this.$route.params.id,
        options: { page },
      });
    },
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
